import AppTitle from '@theme/components/atom/AppTitle'
import BrandBox from '@theme/components/content/BrandBox'
import Container from '@theme/components/utils/Container'
import Divider from '@theme/components/molecule/Divider'
import HeadingLevel from '~/mixins/HeadingLevel'
import UtilityGridMixin from '~/mixins/UtilityGridMixin'

export default {
  mixins: [HeadingLevel, UtilityGridMixin],
  components: {
    AppTitle,
    BrandBox,
    Container,
    Divider,
  },
  props: {
    brands: {
      type: Array,
      default: () => [],
    },
    fullViewportWidthWrapper: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.HomepageBrands.fullViewportWidthWrapper
      },
    },
    headingClass: {
      type: String,
      default: 'Title--alpha',
    },
    headingText: {
      type: String,
      default() {
        return this.$t('HomepageBrands.heading')
      },
    },
    perLine: {
      type: Object,
      default() {
        return this.$themeSettings.components.HomepageBrands.perLine
      },
    },
    textBelowHeading: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columnGridClass: '',
    }
  },
  mounted() {
    this.columnGridClass = this.computeColumnClass(this.perLine)
  },
}
