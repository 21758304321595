import AppImage from '@theme/components/atom/AppImage'
import AppLink from '@theme/components/atom/AppLink'

export default {
  components: {
    AppImage,
    AppLink,
  },
  data() {
    return {
      image: {
        width: 400,
      },
    }
  },
  props: {
    brand: {
      type: Object,
      required: true,
    },
    imageClass: {
      type: String,
      default: 'BrandBox__image',
    },
    lazyImage: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    link() {
      return this.brand.linked_virtual_category &&
        this.brand.linked_virtual_category.length > 0 &&
        this.brand.linked_virtual_category[0].entity
        ? this.localePath({ name: 'all', params: [this.brand.linked_virtual_category[0].entity.page_meta.slug] })
        : '#'
    },
  },
}
